import { Routes, Route } from 'react-router-dom';
import './App.css';
import AppBarFG from './Components/NavBar/AppBarFG';
import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material';
import HomePage from './Components/HomePage/HomePage';
import FAQPage from './Components/FAQPage/FAQPage';
import DownloadPage from './Components/DownloadPage/DownloadPage';
import AboutPage from './Components/AboutPage/AboutPage';
import Footer from './Components/NavBar/Footer';
import ContactPage from './Components/ContactPage/ContactPage';
import { QueryClient, QueryClientProvider } from 'react-query';
import ResetPasswordPage from './Components/ResetPasswordPage';
import PrivacyPolicy from './Components/PrivacyPolicy';

let theme = createTheme({
  palette: {
    primary: {
      main: '#845ac0',
      light: '#E1D5FFFF'
    },
    secondary: {
      main: '#e2b4bd',
      light: '#FFDFEFFF'
    },
    dark: {
      main: '#0D1821FF',
      light: '#0000008A'
    }
  }
})

theme = createTheme(theme, {
  palette: {
    white: theme.palette.augmentColor({
      color: {
        main: '#FFFFFF',
      },
      name: 'white',
    }),
  },
})

theme = responsiveFontSizes(theme);

const queryClient = new QueryClient();

function FamilyGiftApp() {
  return (

    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
      <div className="App">
        <AppBarFG />
          <Routes>
            <Route path="/" element={<HomePage/>}/>
            <Route path="/about" element={<AboutPage/>}/>
            <Route path="/download" element={<DownloadPage/>}/>
            <Route path="/faq" element={<FAQPage/>}/>
            <Route path="/contact" element={<ContactPage/>}/>
            <Route path="/resetPasswordMail" element={<ResetPasswordPage/>}/>
            <Route path="/privacy" element={<PrivacyPolicy/>}/>
          </Routes>
        <Footer/>
      </div>
      </QueryClientProvider>
    </ThemeProvider>

  );
}

export default FamilyGiftApp;
