import {
  AppBar,
  Button,
  Toolbar,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import DrawerComponent from "./DrawerComponent";
import { NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";


function AppBarFG() {
  const theme = useTheme();
  const {t} = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const navigate = useNavigate();

  const MyButtonStyle = styled(NavLink)(({ theme }) => ({
    color: "white",
    fontWeight: "bold",
    alignItems: "center",
    borderRadius: "32px",
    fontSize: 18,
    margin:"0px",
    padding: "16px",
    textDecoration:"none",
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      boxShadow: "white",
      color: theme.palette.primary.main,
      fontSize: 18,

    },
  }));

  const FamilyGiftButtonStyle = styled(NavLink)(({ theme }) => ({
    flexGrow: 1,
    color: "white",
    fontWeight: "bold",
    borderRadius: "32px",
    fontSize: 20,
    margin:"8px",
    padding: "8px",
    textDecoration:"none",
  }));

  return (
    <AppBar position="sticky">
      <Toolbar>
        <img
          src="/assets/family_gift_logo_white.svg"
          alt="Family Gift Logo"
          style={{ height: "52px", padding: "8px" }}
          onClick={() => navigate("/")}
        />
        <FamilyGiftButtonStyle to="/">Family Gift</FamilyGiftButtonStyle>
        {isMobile ? (
          <DrawerComponent />
        ) : (
          <div>
            <MyButtonStyle to="/">{t("home")}</MyButtonStyle>
            <MyButtonStyle to="/download">{t("theApplication")}</MyButtonStyle>
            <MyButtonStyle to="/faq">{t("howItWorksTitle")}</MyButtonStyle>
            <MyButtonStyle to="/contact">{t("contact")}</MyButtonStyle>
            <MyButtonStyle to="/about">{t("about")}</MyButtonStyle>
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default AppBarFG;
