import { Box, Button, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { fetchData, postData } from "../api/apiService";
import { useTheme } from "@emotion/react";
import AvatarPicture from "./ReusableComponents/AvatarPicture";
import { useTranslation } from "react-i18next";

function ResetPasswordPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const tokenResetPassword = searchParams.get("token");
  const theme = useTheme();
  const { data, isLoading, isError, error } = useQuery(
    "reset_password",
    () => fetchData(
        "/getInfoWithReinitialisationToken?token=" + tokenResetPassword
      ),
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      enabled: true,
      retry: false
    }
  );

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorPassword, setErrorPassword] = useState(false);
  const [errorConfirmPassword, setErrorConfirmPassword] = useState(false);
  const [passwordChanged, setPasswordChanged] = useState(false);

  const mutation = useMutation(
    (data) => postData("/resetPassword?token=" + tokenResetPassword, data),
    
  );

  const handleSubmit = (event) => {
    event.preventDefault();
    if (password.length < 7) {
      setErrorPassword(true);
    } else if (password !== confirmPassword) {
      setErrorConfirmPassword(true);
    } else {
      var data = {
        password: password,
      };
      mutation.mutate(data);
      setPasswordChanged(true);
    }
  };
  
  function errorMessageFetch(errorCode){
    switch(errorCode){
      case 498: return t("expiredToken");
      case 499: return t("unfoundToken");
      default: return t("serverError");
    }
  }

  return (
    <Box
      sx={{
        padding: "16px",
      }}
    >
      <Box
        sx={{
          margin: "auto",
          marginTop: "16px",
          borderRadius: "40px",
          bgcolor: "primary.light",
          padding: "20px",
          width: "auto",
          maxWidth: "800px",
          alignContent: "center",
          justifyContent: "center",
          justifyItems: "center",
          textAlign: "center",
        }}
      >
        <img
          src="/assets/family_gift_logo_simple.svg"
          alt="Family Gift Logo"
          style={{ width: "100%", maxWidth: "200px", padding: "8px" }}
        />
        {isLoading ? (
          <Box>
            <p>{t("dataLoading")}</p>
          </Box>
        ) : isError ? (
          <Box>
            <p>{t("errorCodeX", {errorCode: error.response.status})}</p>
            <p>{errorMessageFetch(error.response.status)}</p>
          </Box>
        ) : (
          <Box
            sx={{
              margin: "auto",
              marginTop: "16px",
              padding: "20px",
              width: "auto",
              maxWidth: "800px",
              alignContent: "center",
              justifyContent: "center",
              justifyItems: "center",
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              variant="h4"
              textAlign={"center"}
              color={"primary"}
              margin="8px"
              padding="8px"
              fontWeight={"600"}
            >
              {t("helloUserName", {userName: data.user.username})}
            </Typography>
            <Typography
              variant="h5"
              textAlign={"center"}
              color={"primary"}
              margin="8px"
              padding="8px"
              fontWeight={"500"}
            >
              {t("pleaseWriteNewPassword")}
            </Typography>
            { mutation.isLoading ? 
            (<Typography
              variant="h4"
              textAlign={"center"}
              color={"primary"}
              margin="8px"
              padding="8px"
              fontWeight={"600"}
            >
              {t("editingYourPassword")}
            </Typography>) : 
            mutation.isError ? (
              <Typography
              variant="h4"
              textAlign={"center"}
              color={"primary"}
              margin="8px"
              padding="8px"
              fontWeight={"600"}
            >
              {errorMessageFetch(mutation.error.response.status)}
            </Typography>
            ) : passwordChanged ? (
              <Box>
            <Typography
              variant="h4"
              textAlign={"center"}
              color={"primary"}
              margin="8px"
              padding="8px"
              fontWeight={"600"}
            >
              {t("changePasswordSuccess")}
            </Typography>
            <Button
                sx={{
                  marginTop: "24px",
                }}
                variant="contained"
                color="primary"
                onClick={() => {
                  console.log("onclickbutton termined");
                  navigate("/");}
                }
              >
               {t("goBackHome")}
              </Button>
            </Box>
            ) : 
            (<form onSubmit={handleSubmit}>
              <TextField
                required
                variant="outlined"
                onChange={(e) => {
                  setErrorConfirmPassword(false)
                  setErrorPassword(false)
                  setPassword(e.target.value)
                }}
                value={password}
                fullWidth
                color="primary"
                type="password"
                InputProps={{
                  style: {
                    borderRadius: "16px",
                  },
                }}
                label={t("yourPassword")}
                sx={{
                  margin: "8px",
                  background: "white",
                  borderRadius: "16px",
                }}
                error={errorPassword}
                helperText={
                  errorPassword
                    ? t("mustBeAtLeast8characters")
                    : ""
                }
              />

              <TextField
                required
                error={errorConfirmPassword}
                helperText={
                  errorConfirmPassword
                    ? t("unidenticalPasswords")
                    : ""
                }
                variant="outlined"
                label={t("confirmYourPassword")}
                onChange={(e) => {
                  setErrorConfirmPassword(false)
                  setErrorPassword(false)
                  setConfirmPassword(e.target.value)
                }}
                value={confirmPassword}
                fullWidth
                InputProps={{
                  style: {
                    borderRadius: "16px",
                  },
                }}
                color="primary"
                type="password"
                sx={{
                  margin: "8px",
                  background: "white",
                  borderRadius: "16px",
                }}
              />

              <Button
                sx={{
                  marginTop: "24px",
                }}
                variant="contained"
                color="primary"
                type="submit"
              >
                {t("resetPassword")}
              </Button>
            </form>)}
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default ResetPasswordPage;
