import React, { useState } from "react";
import {
  IconButton,
  ListItem,
  List,
  Drawer,
  Button,
  styled,
} from "@mui/material";
import { Menu } from "@mui/icons-material";
import { NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";


function DrawerComponent() {
  const [openDrawer, setOpenDrawer] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const MyButtonStyle = styled(NavLink)(({ theme }) => ({
    color: theme.palette.primary.main,
    fontWeight: "bold",
    alignItems: "center",
    justifyContent: "center",
    alignContent: "center",
    borderRadius: "32px",
    fontSize: 16,
    margin: "auto",
    padding: "16px",
    textDecoration: "none",
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      boxShadow: "white",
      color: theme.palette.primary.main,
      fontSize: 16,
    },
  }));

  return (
    <>
      <Drawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        anchor="right"
      >
        <List>
          <ListItem>
            <img
              src="/assets/family_gift_logo_simple.svg"
              alt="Family Gift Logo"
              onClick={() => {
                navigate("/");
                setOpenDrawer(!openDrawer);
              }}
              style={{
                height: "100px",
                width: "100px",
                padding: "24px",
                margin: "auto",
              }}
            />
          </ListItem>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <MyButtonStyle to="/">{t("home")}</MyButtonStyle>
          </ListItem>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <MyButtonStyle to="/download">{t("theApplication")}</MyButtonStyle>
          </ListItem>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <MyButtonStyle to="/faq">{t("howItWorksTitle")}</MyButtonStyle>
          </ListItem>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <MyButtonStyle to="/contact">{t("contact")}</MyButtonStyle>
          </ListItem>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <MyButtonStyle to="/about">{t("about")}</MyButtonStyle>
          </ListItem>
        </List>
      </Drawer>
      <IconButton color="white" onClick={() => setOpenDrawer(!openDrawer)}>
        <Menu>Menu</Menu>
      </IconButton>
    </>
  );
}

export default DrawerComponent;
