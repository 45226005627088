import axios from "axios";

const apiClient = axios.create({
  //baseURL: "http://192.168.1.25:8080/family_gift", // Replace with your API base URL
  baseURL:"https://api.family-gift.fr:4000/family_gift"
});

export const fetchData = async (endpoint) => {
  try {
    const response = await apiClient.get(endpoint);
    console.log(response.data)
    return response.data;
  } catch (error) {
    throw error; // Propagate the error to the caller
  }
};

export const postData = async (endpoint, data) => {
  try{
  const response = await apiClient.post(endpoint, data);
  return response.data;
} catch (error) {
  throw error;
}
};
