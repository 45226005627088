import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import frJSON from './locales/fr.json';
import enJSON from './locales/en.json';
import LanguageDetector from 'i18next-browser-languagedetector';


i18n
.use(initReactI18next)
.use(LanguageDetector)
.init({
 resources: {
    en: {...enJSON},
    fr: {...frJSON},
 },
 load: 'languageOnly',
 detection: {order: ['navigator']},
 debug: true,
 whitelist: ['en', 'fr'],

 //lng: "en", 
 fallbackLng: "en",
 interpolation: {
    escapeValue: false,
 }
});

export default i18n;